import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Autosave from '../../../src/autosave';
import './DateTimePicker.scss';

const DatePickerField = ({
  currentDateTime,
  maxDaysOut,
  minDaysOut,
  allowToSelectPastTime,
  label,
  id,
  name,
  buttonText,
  placeholderText,
}) => {
  const [startDateTime, setStartDateTime] = useState(
    currentDateTime ? moment(currentDateTime).toDate() : null
  );

  useEffect(() => {
    async function autosaveSettings() {
      await Autosave.autosaveSettings();
    }

    autosaveSettings();
  }, [startDateTime]);

  return (
    <div className="DatePickerField">
      <Row>
        <Col xs={12}>
          <label className="input-label">{label} - In your local time zone</label>
        </Col>
      </Row>
      <Row className="input-action-group">
        <Col md={9}>
          <DatePicker
            onChange={(date) => setStartDateTime(date)}
            minDate={moment().subtract(minDaysOut, 'd').toDate()}
            maxDate={moment().add(maxDaysOut, 'd').toDate()}
            minTime={
              !allowToSelectPastTime && startDateTime < moment().endOf('day').toDate()
                ? moment().toDate()
                : null
            }
            maxTime={
              !allowToSelectPastTime && startDateTime < moment().endOf('day').toDate()
                ? moment().endOf('day').toDate()
                : null
            }
            selected={startDateTime ? new Date(startDateTime) : null}
            showTimeSelect
            autoComplete="off"
            dateFormat="MMMM d, yyyy h:mm aa"
            timeIntervals={5}
            id={id}
            name={name}
            placeholderText={placeholderText}
            onChangeRaw={(e) => e.preventDefault()}
          />
        </Col>
        <Col md={3}>
          <Button
            id="clean-btn"
            className={`button --white ${!startDateTime ? 'disabled --grey' : ''}`}
            onClick={() => setStartDateTime()}
          >
            {buttonText || 'Clear'}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DatePickerField;
